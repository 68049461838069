import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import Entrance from "images/duegaard/DSC04787(1).jpg";
import Lunch from "images/duegaard/DSC02050.jpg";
import Haven from "images/duegaard/DSC04809.jpg";
/* import MeetingRoomMain from "images/duegaard/DSC04925.jpg";
import MeetingRoom2 from "images/duegaard/DSC04850.jpg";
import MeetingRoom3 from "images/duegaard/Meeting.jpeg"; */

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
//const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;


const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:
        Entrance,
      title: "Charmerende indretning",
      description:
        "Allerede når man træder ind ad hoveddøren, fornemmer man at huset ikke bare er en kontorbygning. De mange detaljer fra den historiske ejendom er nænsomt renoveret samtidig med at arbejdspladserne til fulde lever op til kravene om en nutidig og moderne arbejdsplads. Her er ingen skæve gulve, træk fra vinduer eller andet, som man ofte oplever i et charmerende miljø.",
   //   url: "https://timerse.com"
   // For at url virker skal                 <Link href={card.url}>See Event Details</Link> ind under desc
    },

    {
      imageSrc:
        Lunch,
   //   subtitle: "Free",
      title: "Frokoststue",
      description:
        "Duegårdens frokoststue er indrettet som et afslappende cafemiljø, hvor vi ofte mødes over en cafe latte eller popper friske popcorn.",
  //    url: "https://timerse.com"
    },



/*     {
      imageSrc:
        Lunch,
   //   subtitle: "Free",
      title: "Beliggenhed",
      description:
        "Duegården ligger i udkanten af Kvistgårds industriområde, hvor virksomheder, som Bavarian Nordic, Løgstrup Steel, Coloplast har til huse. Afstanden til København er ca. 40 km / 36 min i bil. Der er naturligvis gratis parkeringsplads.",
  //    url: "https://timerse.com"
    }, */
/* 
    {
      imageSrc:
        MeetingRoom2,
    // Indsæt video af rum her 
   //   subtitle: "Free",
      title: "Pejsestue",
      description:
        "Når vi afholder eksterne møder benytter vi ofte pejsestuen, hvor den fine gamle porcelænspejs pryder rummet og giver en hyggelig og afslappende atmosfære.",
      //url: "https://timerse.com"
    },

    {
      imageSrc:
        MeetingRoomMain,
    // Indsæt video af rum her 
   //   subtitle: "Free",
      title: "Digitalt mødelokale",
      description:
        "Afholder du digitale møder, vil du blive glad for det specielt indrettede lokale til digitale møder. Klik her og se en kort video, der viser hvordan dine mødedeltagere oplever et digitalt møde med dig.",
      //url: "https://timerse.com"
    },

    {
      imageSrc:
        MeetingRoom3,
    // Indsæt video af rum her 
   //   subtitle: "Free",
      title: "Mødelokale",
      description:
        "Vi kalder dette lokale for ’projektrummet’, da vi mest benytter lokalet, når vi er flere i huset, der arbejder sammen om et fælles projekt.",
      //url: "https://timerse.com"
    }, */

    {
      imageSrc:
        Haven,
      title: "Haven",
      description:
        "Duegården er omkranset af en have på 2070 m2 med gamle høje træer. Får du lyst til at trække lidt frisk luft med en kop kaffe, skal du ikke stå ud til en kedelig hovedvej. Er du ryger, har vi også tænkt på dig. Der er i haven indrettet en overdækket rygeplads. ",
 //     url: "https://timerse.com"
    }
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Bliv en del af et fællesskab</HeadingTitle>
          <HeadingDescription>
          At være lejer i Duegården er også at være en del af et fællesskab. Uanset om du er konsulent, webdesigner, foredragsholder, arkitekt eller noget helt femte, er det dejligt, at kunne vende tanker, glæder og problemstillinger med andre ‘kollegaer’. Faktisk giver det ofte den bedste sparring at drøfte en problemstilling med nogen, der er fra en helt anden branche end én selv.
          Bliv en del af Duegårdens kontorfællesskab og slip for at tænke på rengøring af køkken og toilet, internetforbindelse, alarm, indkøb af kaffe, el og varme, driftomkostninger mv, samt lange opsigelser.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
