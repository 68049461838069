import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import MeetingRoomMain from "images/duegaard/DSC04925.jpg";

const Container = tw.div`relative`;
/* const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url("${MeetingRoomMain}");
`;
 */
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Title = tw.h4`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight text-white`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-white`;
const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

//const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

export default ({
  heading = "Digitalt mødelokale",
  description = "Afholder du digitale møder, vil du blive glad for det specielt indrettede lokale til digitale møder.",
  // primaryButtonText="Get Started",
  // primaryButtonUrl="#",
  //watchVideoButtonText="Se video",
  //watchVideoYoutubeUrl="https://www.youtube.com/embed/WDlu1OhvYBM",
  //imageSrc=MeetingRoomMain,
  //imageCss=null,
  //imageDecoratorBlob = false,
//  <StyledResponsiveVideoEmbed
//  url="https://www.youtube.com/embed/JD_w50R-6K8?disablekb=1&enablejsapi=1&fs=0&modestbranding=1&color=white"
//  background="transparent"
//  />


}) => {
  return (
    <Container>
      <HeroContainer>
        <TwoColumn>
          <LeftColumn>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>
          </LeftColumn>
          <RightColumn>
            <MeetingRoomMain></MeetingRoomMain>
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
