import React, { useState } from "react";
//import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
//import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
//import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import House from "images/duegaard/DSC02240.jpg";
/* import DataTable from "react-data-table-component";
import Kontor from "components/faqs/Kontor";
import Arbejdspladser from "./Arbejdspladser"; */
/* 
const columns = [
  {
      name: '',
      selector: row => row.title,
  },
  {
      name: 'Møbleret',
      selector: row => row.year,
  },
  {
      name: 'Umøbleret',
      selector: row => row.price
  },
];

const data = [
  {
      id: 1,
      title: '1 person',
      year: '3000',
      price: '2800',
  },
  {
      id: 2,
      title: '2 personer',
      year: '3300',
      price: '3000',
  },
]

function LukketKontor() {
  return (
      <DataTable
          columns={columns}
          data={data}
      />
  );
}; */

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const TwoColumn = tw.div`flex`;
const Column = tw.div``;

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`,
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
/* const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-blue-900 text-gray-100 p-1 rounded-full group-hover:bg-blue-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`; */
const Answer = tw.dd`text-sm sm:text-base`;
//pointer-events-none
export default ({
  subheading = "",
  heading = "Diverse",
  description = "",
  imageSrc = House,
  imageContain = false,
  imageShadow = true,
  faqs = null,
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    /*  {
      question: "Arbejdspladser",
      answer:
        "Du har din faste arbejdsplads i et kontorlandskab med plads til 9 personer.",
      table: <Arbejdspladser></Arbejdspladser>
    },
    {
      question: "Lukkede kontorer",
      answer:
        "",
      table: <LukketKontor></LukketKontor>
    },
    {
      question: "6 mandskontor",
      answer:
        "6- mandskontoret består af 2 sammenhængende kontorer, hvor der er plads til hhv. 4 og 2 personer. Rummet med plads til 2 personer, kan også benyttes som et privat mødelokale, eller et chefkontor.",
      table: <Kontor></Kontor>,
      answer2: "Benytter du kontoret til 6 personer, vil den månedlige leje udgøre 9.900,00 (møbleret) og 8.700 (umøbleret)."
    },
    */ {
      question: "Historien",
      answer:
        "Duegården er en historisk ejendom opført i 1846, som Nyrup skole. Efter en omfattende brand i 1875, blev ejendommen genopført som nuværende ejendom. I 1933, bliver den ombygget, til lukusbolig til kredslæge Henrik W Jastrau, som var den læge, der i 1918 tilså de første patienter med den spanske syge på et dansk skib i Helsingør.",
    },
    {
      question: "Beliggenhed",
      answer:
        "Duegården ligger i udkanten af Kvistgårds industriområde, hvor virksomheder, som Bavarian Nordic, Løgstrup Steel, Coloplast har til huse. Afstanden til København er ca. 40 km / 36 min i bil. Der er naturligvis gratis parkeringsplads.",
    },
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  return (
    <Container>
      <Content>
        <TwoColumn>
          <Column tw="hidden lg:block w-5/12 flex-shrink-0">
            <Image
              imageContain={imageContain}
              imageShadow={imageShadow}
              imageSrc={imageSrc}
            />
          </Column>
          <Column>
            <FAQContent>
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <FAQSContainer>
                {faqs.map((faq, index) => (
                  <FAQ>
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                    </Question>
                    <Answer>
                      {faq.answer}
                      {/* {faq.table}
                      {faq.answer2} */}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};
