import React from "react";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
// import { render } from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
//import MeetingRoomImgScr from "images/duegaard/Meeting.jpeg";
import Image2 from "images/duegaard/DSC02050.jpg";
import Image3 from "images/duegaard/DSC02128.jpg";
import Image4 from "images/duegaard/DSC02132.jpg";
import Image5 from "images/duegaard/DSC02138.jpg";
import Image6 from "images/duegaard/DSC02147.jpg";
import Image7 from "images/duegaard/DSC02162.jpg";
import Image8 from "images/duegaard/DSC02177.jpg";
import Image9 from "images/duegaard/DSC02181.jpg";
import Image10 from "images/duegaard/DSC02200.jpg";
import Image11 from "images/duegaard/DSC02232.jpg";
import Image12 from "images/duegaard/DSC02240.jpg";
import Image13 from "images/duegaard/DSC04787(1).jpg";
import Image14 from "images/duegaard/DSC04809.jpg";
import Image15 from "images/duegaard/DSC04821.jpg";
//import Image16 from "images/duegaard/DSC04821.jpg";
import Image17 from "images/duegaard/DSC04850.jpg";
import Image18 from "images/duegaard/DSC04859.jpg";
import Image19 from "images/duegaard/DSC04893.jpg";
import Image20 from "images/duegaard/DSC04903.jpg";
import Image21 from "images/duegaard/DSC04910.jpg";
import Image22 from "images/duegaard/DSC04912.jpg";
import Image23 from "images/duegaard/DSC04925.jpg";
import Image24 from "images/duegaard/Plan2.png"

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
//const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;
//import MeetingRoomImgScre from "images/duegaard/DSC02050.jpg";

/* const getConfigurableProps = () => ({
  showArrows: true,
  showStatus: true,
  showIndicators: true,
  infiniteLoop: true,
  //showThumbs: boolean('showThumbs', true, tooglesGroupId),
  //useKeyboardArrows: boolean('useKeyboardArrows', true, tooglesGroupId),
  //autoPlay: boolean('autoPlay', true, tooglesGroupId),
  //stopOnHover: boolean('stopOnHover', true, tooglesGroupId),
  //swipeable: boolean('swipeable', true, tooglesGroupId),
  //dynamicHeight: boolean('dynamicHeight', true, tooglesGroupId),
  //emulateTouch: boolean('emulateTouch', true, tooglesGroupId),
  //autoFocus: boolean('autoFocus', false, tooglesGroupId),
  //thumbWidth: number('thumbWidth', 100, {}, valuesGroupId),
  //selectedItem: number('selectedItem', 0, {}, valuesGroupId),
  interval: 2000,
  transitionTime: 500,
  swipeScrollTolerance: 5,
  //ariaLabel: text('ariaLabel', undefined),
});

export const centerMode = () => (
  <Carousel
      infiniteLoop
      centerMode
      centerSlidePercentage={80}
      {...getConfigurableProps()}
  >
  </Carousel>
); */

/* export default {
  title: '01 - Basic',
  component: Carousel,
}; */

/* const createCarouselItemImage = (index, options = {}) => (
  <div key={index}>
      <img src={`/assets/${index}.jpeg`} />
      <p className="legend">Legend 2</p>
    </div>
  </div>
);
const baseChildren = <div>{[1, 2, 3, 4, 5].map(createCarouselItemImage)}</div>;
      <img src={`/assets/${index}.jpeg`} /> */
//copy paste noget tekst på forsiden - og så hent billeder ind her

export default () => (
  /*    <div>
  <img alt="" src="http://46.101.143.114/wp-content/uploads/2021/03/cropped-cropped-IMG_1991-scaled-e1615650984793.jpg" />
  <p className="legend">Legend 1</p>
 </div>  */
  //Hent billeder ind her
  <Container>
    <ContentWithPaddingXl>
      <HeadingInfoContainer>
        <HeadingTitle>
          GALLERI
        </HeadingTitle>
      </HeadingInfoContainer>
      
      <Carousel
        centerMode
        autoPlay
        interval={3000}
        infiniteLoop
        showStatus
        stopOnHover={true}
        showArrows={false}
        dynamicHeight={true}
        transitionTime={1000}
      >
        <div>
          <img alt="" src={Image2} />
          {/* <p className="legend">Legend 2</p> */}
        </div>
        <div>
          <img alt="" src={Image3} />
        </div>
        <div>
          <img alt="" src={Image4} />
        </div>
        <div>
          <img alt="" src={Image5} />
        </div>
        <div>
          <img alt="" src={Image6} />
        </div>
        <div>
          <img alt="" src={Image7} />
        </div>
        <div>
          <img alt="" src={Image8} />
        </div>
        <div>
          <img alt="" src={Image9} />
        </div>
        <div>
          <img alt="" src={Image10} />
        </div>
        <div>
          <img alt="" src={Image11} />
        </div>
        <div>
          <img alt="" src={Image12} />
        </div>
        <div>
          <img alt="" src={Image13} />
        </div>
        <div>
          <img alt="" src={Image14} />
        </div>
        <div>
          <img alt="" src={Image15} />
        </div>
        <div>
          <img alt="" src={Image18} />
        </div>
        <div>
          <img alt="" src={Image19} />
        </div>
        <div>
          <img alt="" src={Image20} />
        </div>
        <div>
          <img alt="" src={Image17} />
        </div>
        <div>
          <img alt="" src={Image21} />
        </div>
        <div>
          <img alt="" src={Image22} />
        </div>
        <div>
          <img alt="" src={Image23} />
        </div>
        <div>
          <img alt="" src={Image24} />
        </div>
      </Carousel>
    </ContentWithPaddingXl>
  </Container>
);
