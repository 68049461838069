import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(
  Column
)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const Title = tw.h4`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
//const LinkListItem = tw.li`mt-3`;
//const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black text-black`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;
const Map = () => {
  return (
    <div className="google-map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1115.5097847396776!2d12.51206225835597!3d56.001013695155095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46523759632c7041%3A0xa3ac3f01451de5d3!2sB%C3%B8geskovvej%2013%2C%203490%20Kvistg%C3%A5rd!5e0!3m2!1sen!2sdk!4v1632315526104!5m2!1sen!2sdk"
        width="400"
        height="300"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
};

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText>Duegaarden Kontorfællesskab</LogoText>
          </LogoContainer>
          <CompanyDescription>..</CompanyDescription>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com/duegaarden.net">
              <FacebookIcon />
            </SocialLink>
            {/*             <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink> */}
          </SocialLinksContainer>
        </WideColumn>
        <Column>
          <ColumnHeading>Adresse</ColumnHeading>
          <LinkList>
            <Description>Bøgeskovvej 13, 3490 Kvistgård</Description>
            <Description>
              julskov@gmail.com
              <br />
              +4540740900
            </Description>
          </LinkList>
        </Column>
        <Column>
          <Map />
        </Column>
        <Column></Column>
      </FiveColumns>
    </Container>
  );
};
